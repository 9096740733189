
<template lang="pug">
    .main-survey(v-if="internal_value")
      
      template(v-if="send_mode && project_info")
        
        .row
          .col-6
            b-card(no-body)
              b-card-header()
                .d-flex.justify-content-between.align-items-center
                  h6 Template
            
                  b-btn(size="sm", variant="outline-primary" @click="send_mode=false") Edit
                
              b-card-body(style="max-height: calc(100vh - 400px); overflow-y: scroll" )
                div(v-for="(group, i) in internal_value.groups")
                  h6 {{group.name}}
                  b-card(no-body v-for="field in group.items", :key="field.name", style="border: 1px solid #4B4B4A").mb-3.m-1 
                    div(header-tag="header" class="p-0", style="background-color: transparent").header
                        .row
                          .col-8
                            b-button(v-b-toggle="'accordion-' + field.name", variant="link", :disabled="current_edit != 'group_'+group.name +i").text-left.w-100
                          
                              i.fa.fa-align-justify.handle-sub.mr-2.gray-info(v-if="current_edit == 'group_'+group.name+i")
                              | {{field.label}}
                              .text-gray.small {{field.description}}
                          .col-4
                                    .float-right
                                        .p-2.text-right
                                          
                                            label.text-gray {{data_type_mappings[field.data_type] ? data_type_mappings[field.data_type].split("-")[0] : ""}}
                                            //.text-gray.small {{data_type_mappings[field.data_type] ? data_type_mappings[field.data_type].split("-")[1] : ""}}
          

          .col-6
            
            b-card(no-body)
              b-card-header()
                .d-flex.justify-content-between.align-items-center
                  h6 Project {{project_info.project.name}}
              b-card-body().p-0
                b-list-group(style="max-height: calc(100vh - 400px); overflow-y: scroll")
                  b-list-group-item(v-for="po in project_info.project_organizations" button, @click="select_org(po.relations.organization_id)",v-if="po") 
                    .media
                      div(class="feed-image-small" :style="'background: url(https://images.hytechnologies.co/unsafe/50x50/logo.clearbit.com/' + po.relations.organization_id.web_entity + ') center 0% / cover no-repeat;'").mr-3
                      .media-body 
                        | {{po.relations.organization_id.name}}
                        span(v-if="po.relations.organization_id.email")
                        .small {{po.relations.organization_id.email}}
                        template(v-if="!po.relations.organization_id.email")
                          .small.text-danger No email address
                  
        .row.mt-5
        
          .col-12
        
            b-form()
              .row
               
                
                .col-12
                  .float-right.text-right
                    b-btn-group()
                      b-btn(variant="outline-secondary", @click="send_mode=false") Cancel
                      b-btn(variant="primary", :disabled="current_edit", @click="send_surveys()") Send now
                    .text-gray.small.mt-1() Send survey to {{project_info.project_organizations.length}} companies


      template(v-if="!send_mode")
        .row.mb-2
            .col-12
            
              b-card(no-body)
                b-card-header().d-flex.justify-content-between
                  | Survey Info
                  b-link(variant="link", size="sm", @click="enter_edit('main_name')", v-if="current_edit != 'main_name'")
                    i.fas.fa-pen.text-muted
                template(v-if="current_edit == 'main_name'")
                  b-card-text().p-3
           
                    b-form-group(label="Survey Name")
                      b-input(v-model="internal_value.name", placeholder="Survey name", size="sm")
                    
                    b-form-group(label="Email Invite Text")
                      b-textarea(:rows="4", v-model="internal_value.email_invite_text", placeholder="Please enter the text that will appear in the Email send to participants with the link to your survey", size="sm")
                    
                    b-form-group(label="Introduction Text")
                      b-textarea(:rows="4", v-model="internal_value.description", placeholder="Please give some intorduction to the purpose and content of this survey", size="sm")

                      b-card-footer()
                    
                    .float-right
                      b-btn-group()
                        b-btn(variant="outline-secondary", @click="cancel_edit()") Cancel
                        b-btn(variant="outline-primary", @click="save()") Save
                
                b-card-text(v-if="current_edit != 'main_name'").p-3
                    h4(@click="enter_edit('main_name')") {{internal_value.name}} 
                    
                    label Intro text
                    p(@click="enter_edit('main_name')") {{internal_value.description}}

                    label Email text
                    p(@click="enter_edit('main_name')") {{internal_value.email_invite_text}}
        .row.mt-5
            .col
             
                draggable(:animation="200", handle=".handle-top" ghost-class="ghost-card"  @change="changed(null, $event)", v-model="internal_value.groups" group="tops")
                  b-card(no-body v-for="(group, i) in internal_value.groups").mt-2.mb-4 
                        b-card-header().d-flex.justify-content-between
                         
                            b-input-group()
                              i.fa.fa-align-justify.handle-top.gray-info.mr-3
                              | Section {{i+1}}
                            div(v-if="current_edit != 'group_' + group.name + i")
                              b-link(variant="link", size="sm", @click="new_group = Object.assign({}, group); enter_edit('group_' + group.name + i)")
                                i.fas.fa-pen.text-muted
                             
                          
                        .p-3
                          template(v-if="current_edit == 'group_'+group.name + i")
                                
                            b-form-group(label="Section name")
                              b-input(v-model="new_group.name", size="sm", placeholder="Section name")
                            b-form-group(label="Section description")
                              b-textarea(v-model="new_group.description", size="sm", placeholder="Section description").mb-3
                            
                                
                          template(v-else)
                          
                            b-link(@click="new_group = Object.assign({}, group); enter_edit('group_' + group.name + i)") 
                              h4 {{group.name}}
                            b-card-text().text-gray {{group.description}}

                        div.p-3
                            draggable(:animation="200", handle=".handle-sub" ghost-class="ghost-card", @change="changed(group.name, $event)", :list="group.items"  group="subs")
                              
                            
                              b-card(no-body v-for="field in group.items", :key="field.name", style="border: 1px solid #4B4B4A").mb-3.m-1 
                                div(header-tag="header" class="p-0", style="background-color: transparent")
                                  .row
                                    .col-8
                                      b-button(v-b-toggle="'accordion-' + field.name", variant="link", :disabled="current_edit != 'group_'+group.name +i").text-left.w-100
                                    
                                        i.fa.fa-align-justify.handle-sub.mr-2.gray-info(v-if="current_edit == 'group_'+group.name+i")
                                        | {{field.label}}
                                        .text-gray.small {{field.description}}
                                      
                                    .col-4
                                      .float-right
                                          .p-2.text-right
                                            
                                              label.text-gray {{data_type_mappings[field.data_type] ? data_type_mappings[field.data_type].split("-")[0] : ""}}
                                              //.text-gray.small {{data_type_mappings[field.data_type] ? data_type_mappings[field.data_type].split("-")[1] : ""}}
                              
                                b-collapse(:id="'accordion-' + field.name" accordion="my-accordion" role="tabpanel", v-model="visible_collapse[field.name]")
                                  b-card-body().p-3
                            
                                    b-form()
                                      .form-row
                                        .col-6
                                            b-form-group(label="Input type*")
                                              b-dropdown(right :text="data_type_mappings[field.data_type]", variant="outline-secondary", size="sm", style="min-width: 200px")#data_type
                                                template(v-slot:button-content)
                                                  | {{data_type_mappings[field.data_type] ? data_type_mappings[field.data_type].split("-")[0] : ""}}

                                                b-dropdown-item(href="#", @click="field.data_type = k" v-for="(it, k) in data_type_mappings") {{it}}
                                      
                                      .form-row.mt-1

                                          //.col-6
                                              b-form-group(label="Section")
                                                  b-input(v-model="active.group", size="sm", required)
                                          .col-6
                                              b-form-group(label="Label*")
                                                  //b-input(v-model="field.name", size="sm", placeholder="Type a field name")#name-name
                                                  b-input(v-model="field.label", size="sm", placeholder="Type a field label name", @change="field.label = (!field.label ? 'Untitled Field' : field.label)")#label-name

                                    
                                      .form-row(v-if="field.data_type=='select' || field.data_type=='tags'")

                                          .col-6
                                              b-form-group(label="Field type options")
                                                
                                                  v-select( placeholder="Options", :multiple="true", :taggable="true", :no-drop="true", v-model="field.options").mt-1.small#options

                                      .form-row(v-if="field.data_type=='money_amount'")

                                          .col-6
                                              b-form-group(label="Currency")
                                                  b-select(:options="['USD', 'EUR']", size="sm", v-model="field.options")#currency
                                    
                                      .form-row(v-if="field.data_type=='textarea'")

                                          .col-6
                                              b-form-group(label="Rows")
                                                  b-input(type="number", size="sm", v-model="field.options.rows", length="4", placeholder="Number of rows for text input")#number-rows
                                            

                                      .form-row
                                          .col-12
                                              b-form-group(label="Description / Help text")
                                                  b-textarea(size="sm", v-model="field.description", placeholder="Type a description or short input help text for the data field")#description
                                  
                                      .form-row
                                          .col-12
                                              b-btn(size="sm", variant="link", @click="remove(field)").text-gray Remove this question
                                              //.float-right
                                                  b-btn(size="sm", variant="link", @click="getData()")#cancel.mr-1 Cancel
                                                  b-btn(size="sm", variant="primary", @click="save()")#save Save
                              
                              template(v-if="current_edit == 'group_'+group.name + i")
                                div(style="border: 1px dashed gray").mt-2.mb-5.m-1
                                  b-btn(variant="link", @click="add_new_field(group)") + Add a new question              
                        
                        template(v-if="current_edit == 'group_'+group.name+i")
                          b-card-footer()
                            .row
                                .col-4
                                  b-btn(size="sm", variant="link", @click="remove_section(group, i)").text-gray Remove this section
                                .col-8
                                  .float-right
                                    b-btn-group()
                                      b-btn(variant="outline-secondary", @click="cancel_edit()") Cancel
                                      b-btn(variant="outline-primary", @click="save_newgroup(group, new_group, i)") Save

                template(v-if="!current_edit")
                  div(style="border: 1px dashed gray").w-100.mt-2.mb-3
                    b-btn(variant="link", @click="add_new_section(internal_value.groups.length)") + Add new section
                
            


                b-form().mt-5
                  .row
                    .col-6
                      //template(v-if="project")
                        label Embedding code
                        pre().small
                          | {{get_template_link(internal_value)}}
                        b-btn(size="sm", variant="outline-secondary").mr-2 Copy Embed code
                        b-link(size="sm").mr-2 Open application form
                        
                        
                      template(v-if="!project")
                        b-form-group(label="Project")
                          ProjectStageSelect(id="projectStageAdd" v-model="selectedProjectStage", :create_option="false", placeholder="Select Project", style="max-width: 300px").small
                          .mt-1(v-if="selectedProjectStage && send_to_specific_usecase")
                            UseCaseSelect(id="usecaseselect" v-model="selectedUseCase", :create_option="false", :project_id="selectedProjectStage").small
                           
                    .col-6
                      .float-right.text-right

                        b-btn(variant="primary", :disabled="current_edit || !selectedProjectStage || internal_value.data_fields.length == 0", :to="'../matching?survey_template_id='+internal_value._id") Select recipients
                        .text-gray.small.mt-1(v-if="current_edit || internal_value.data_fields.length == 0") Leave the edit mode and select a project to send.
                        .text-gray.small.mt-1(v-if="internal_value.data_fields.length == 0") Your survey needs at least one question.
                          


            
        //.row.mt-2(v-if="internal_edit_mode")
          .col-12     
            b-form-group(label="Email-Text")
              b-textarea(v-model="internal_value.email_invite_text", :rows="4")
              
        //.row.mt-2(v-if="internal_edit_mode")
            .col-6
              .small <b>Warning:</b> Changing the template  can lead to data loss for surveys based on this tempalate already filled out.
            .col-6
                .d-flex.align-items-end(v-if="internal_edit_mode")
                    b-btn(variant="outline-secondary",@click="internal_edit_mode=false").ml-auto Cancel
                    b-btn(variant="outline-primary", @click="save()") Save
</template>

<script>

// @ is an alias to /src
//import Config from '@/config'
import Vue from 'vue';
import Config from '@/config'
import draggable from "vuedraggable";

import { mapFields } from 'vuex-map-fields';
import ProjectStageSelect from '@/components/ProjectStageSelect'

export default {
  name: 'SurveyTemplate',
  props: {
    "value":  {
      default: {"name": "", "custom_data": {}}
    },
    
    "edit_mode":  {
      default: false
    },

    "project":  {
      default: null
    },


    "editable":  {
      default: false
    },
    "inline":  {
      default: true
    },
    "user":  {
      default: null
    },
 
  },

    computed: {
      ...mapFields([

        'sidebar.selectedProjectStage',
        'sidebar.selectedUseCase',

      ]),

  },


  watch: {
    'selectedProjectStage': function(val) {
        if(val) {
          this.get_project_info(val);
        }
    },

    value: {
      
      handler(val) {
        
        this.visible_collapse = {};
        this.uid = this._uid;
        this.send_to_specific_usecase = false;
        this.internal_value = {...val}
        this.project_info = null;
        this.send_mode = false;
        this.internal_edit_mode = this.edit_mode;
        this.fallback_internal_value = JSON.parse(JSON.stringify(this.internal_value));
        
        if(this.project) {
          this.selectedProjectStage = this.project._id;
        }

        if(this.selectedProjectStage) {
          this.get_project_info(this.selectedProjectStage);
        }


        this.rebuild_internal();

      }
    }
  },




  data: function () {
    return {
        current_edit: null,
        send_mode: true,
        project_info: null,
        internal_edit_mode: true,
        fallback_internal_value: null,
        visible_collapse: {},
        internal_value: null,
        new_group: {"name": "", "description": ""},
        new_field: {"name": null, "label": "", "data_type": "text"},
        data_type_mappings: {
          "text": "Short text - Provide a line of text",
          "textarea": "Long text - Provide multiple lines of text",
          "slider": "Rating - Select a score from 0-10",
          "date": "Date - Provide a date chosen from a calender",
          "money_amount": "Money amount - Provide a amount in a currency",
          "select": "Option - Choose one from predefined options",
          "tags": "Tags - Choose multiple tags predefined options",
          "percent": "Percent - A percent number", 
          "number": "Number - A number"
        }       
    }
  },
 


  mounted: function() {
    this.visible_collapse = {};
    this.uid = this._uid;
    this.send_mode = false;
    this.internal_value = {...this.value}
    this.internal_edit_mode = this.edit_mode;
    this.fallback_internal_value = JSON.parse(JSON.stringify(this.internal_value));
    this.rebuild_internal();
    
    if(!this.internal_value.name) {
      this.enter_edit("main_name")
    }
    if(this.project) {
      this.selectedProjectStage = this.project._id;
    }
    if(this.selectedProjectStage) {
      this.get_project_info(this.selectedProjectStage);
    }
    
  },

  
  
  methods: {
  

  get_template_link(template) {
                          
    return '<iframe src="' + window.location.protocol + "//" + window.location.host + '/application_external/' +  template.link_token + '" title="W3Schools Free Online Web Tutorials"></iframe>';
  },


  send_surveys() {
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/surveys/send_bulk_to_orgs`, {"project_id": this.project_info.project._id, "organization_ids": this.project_info.project_organizations.map(x => x.organization_id), "survey_template_id": this.internal_value._id}).then(() => {

          this.$bvToast.toast("Surveys send", {
              title: 'Saved',
              variant: 'outline-primary',
              autoHideDelay: 1000,
          });

          location.href ="/projects/"+this.project_info.project._id+"/survey_templates"

          this.$store.dispatch("stop_loading");
      });
  },


  select_org(org) {
    
      this.$store.state.sidebar_project.selected_item = org;

      this.$forceUpdate();

  },

    enter_edit(mode) {
      this.fallback_internal_value = JSON.parse(JSON.stringify(this.internal_value));
      this.current_edit = mode;
      
    },

    cancel_edit() {
      this.current_edit = null;
      this.internal_value = {...this.fallback_internal_value};
      this.collapse_all();
      this.rebuild_internal();
      this.$emit("input", this.internal_value);
    },

    rebuild_internal() {
      
      this.internal_value.groups.forEach((x) => { 
          x.items = [];
      });

      this.internal_value.data_fields.forEach((x) => {
        this.visible_collapse[x] = false;
        let groups = this.internal_value.groups.filter(it => it.name == x.group);
        if(groups && groups.length > 0) {
          if(!groups[0].items.includes(x)) groups[0].items.push(x);
        }
      });
    
      this.$forceUpdate();
    },
       uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      },

      add_new_field(group) {
        let uuid = this.uuid()
        let new_field = JSON.parse(JSON.stringify(this.new_field));
        this.internal_value.data_fields.push(Object.assign(new_field, {group: group.name, "name": uuid}));
        this.visible_collapse[uuid] = true;
      
        this.rebuild_internal();
      },
      
      get_fields_by_group(name) {
          return this.internal_value.data_fields.filter(x => x.group == name);
      },

      add_new_section(i) {
        this.new_group = {"name": "", "description": ""};
      
        this.enter_edit('group_'+i);
         
        this.internal_value.groups.push({"name": "", "description": ""});
        this.rebuild_internal();
      },

      changed(group, event) {
      
        
        if(event.added) {
        
          this.save(false);
        }

        if(event.moved) {
      
          this.save(false);
        
        }

        if(event.user) {
          console.log(group, event);
        }
     
    },

    remove(field) {
      let index = this.internal_value.data_fields.map(x => x.name).indexOf(field.name);
      this.internal_value.data_fields.splice(index, 1);
      this.rebuild_internal();
    },

    remove_section(group, i) {
      if(group.items.length > 0) {
        alert("Section should not contain any data fields. Please delete data fields first.");
        return;
      }
      this.internal_value.groups.splice(i,1);
      this.save();
      this.rebuild_internal();
    },

    collapse_all() {
      Object.keys(this.visible_collapse).forEach((field) => {
        this.visible_collapse[field] = false;
      });
    },


    get_project_info(project_id) {
      
      Vue.http.get(`${Config.config.base_host}/projects/${project_id}?include_orgs=true`, {}).then(res => {
          this.project_info = res.body;
      });
    
    },

    save_newgroup(group, new_group, number) {
      
      this.internal_value.data_fields.forEach((x) => { 
        if(x.group == group.name) {
          x.group = new_group.name;
        }
      });
      let new_groups = [];
      this.internal_value.groups.forEach((x, i) => { 
        if(x.name == group.name && number == i) {
          new_groups.push(new_group);
        } else {
          new_groups.push(x);
        }
      });

      this.internal_value.groups = new_groups;

      this.rebuild_internal();
      this.save(true);
    }, 
      save(close_edit = true) {

        this.$store.dispatch("start_loading");

        if(this.project_info && this.project_info.project._id) {
          this.internal_value.project_id = this.project_info.project._id;
        }

        this.internal_value.data_fields = [];
        if(!this.internal_value.item_type) this.internal_value.item_type = "project_assessment";
        this.internal_value.groups.forEach((group) => { 
          group.items.forEach((item) => {
            item.group = group.name;
            this.internal_value.data_fields.push(item);
          });
          delete group.items;
        });

        this.collapse_all();
        if(!this.internal_value._id) {
            
            this.$store.dispatch("start_loading");    
            Vue.http.post(`${Config.config.base_host}/survey_templates`, this.internal_value).then(res => {
                this.internal_value = res.body;
                this.$emit("input", this.internal_value);
                this.$emit("saved", this.internal_value);
                
                if(close_edit) {
                  //this.edit_mode = false;
                  this.current_edit = null;
                }
                 this.rebuild_internal();
                this.$store.dispatch("stop_loading");
                
                
            });

        } else {

            this.$store.dispatch("start_loading");    
            Vue.http.put(`${Config.config.base_host}/survey_templates/${this.internal_value._id}`, this.internal_value).then(res => {
                this.internal_value = res.body;
                this.$store.dispatch("stop_loading");
                if(close_edit) {
                  //this.edit_mode = false;
                  this.current_edit = null;
                }
                 this.rebuild_internal();
                
                this.$emit("input", this.internal_value);
                this.$emit("saved", this.internal_value);
            });
        
        }

              
      },
      

  },

  components: {
   draggable,
   ProjectStageSelect
  }
}

</script>
<style lang="scss" scoped>
.feed-image {
    width: 50px;
    height: 50px;
}

.btn-link:disabled, .btn-link.disabled {
  color: black;
  text-transform: none !important;
  .text-gray {
    color: #888;
    text-transform: none !important;
  }
}

.main-survey {
  .header {
    .btn-link:disabled, .btn-link.disabled {
      color: black !important;
    }

    .btn {
      text-transform: none !important;
    }
  }
}


</style>
